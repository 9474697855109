@import 'var';
@import 'animation';

body, html {
    padding: 0;
    font-family: 'Roboto';
    font-weight: 300;
    letter-spacing: 1.37px;
    line-height: 1.3;
    background-color: #f2f2f2;
    color: $prime;
    font-size: 14px; }
* {
    box-sizing: border-box;
    margin: 0; }

.txt-bold {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: $prime; }
.txt-light {
    letter-spacing: 1.2px;
    font-weight: 100;
    color: $prime; }
.txt-second {
    color: $second !important; }
h1, .h1 {
    font-size: 33px;
    font-weight: 400;
    @media screen and (min-width: $desktop) {
        font-size: 40px; } }
h2, .h2 {
    font-size: 11px;
    font-weight: 100;
    color: $prime-light;
    @media screen and (min-width: $desktop) {
        font-size: 16px; } }

button {
    padding: 10px 15px;
    border: 1px solid $second;
    border-radius: 25px;
    background: transparent;

    &:hover {
        cursor: pointer; } }

.container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 200px;
    display: flex;
    justify-content: space-between;

    .gc-image {
        border-right: 1px solid $prime-light;
        padding: 30px 25px;
        img {
            height: 140px; } } }

.gc-content {
    padding: 30px 25px;
    width: 650px;
    .gc-header {
        p {
            margin-top: 25px;
            font-size: 20px;
            letter-spacing: 2px;
            font-weight: 100; } }
    .gc-text {
        margin-top: 65px;
        p {
            margin: 15px 0 20px;
            text-align: justify; }
        img {
            width: 100%;
            height: auto; } }
    .heading {
        font-size: 24px;
        font-weight: 300;
        margin: 10px auto 5px;
        border-bottom: 1px solid $prime-light; }

    .links {
        border-top: 1px solid $prime-light;
        padding: 5px 0;
        margin: 50px auto 0;
        span {
            margin-right: 20px;
            text-decoration: none;
            cursor: pointer; } } }

.gc-es-heading {
    position: fixed;
    bottom: 20px;
    right: 20px;
    margin-top: 50px;
    h1 {
        font-size: 20px; }
    h2 {
        font-size: 8px;
        text-align: left; } }



.menu {
    visibility: collapse; }

.menu.show {
    visibility: visible; }

.menu-container {
    z-index: 1000;
    background: #fff;
    border: 1px solid #999;
    border-radius: 15px;
    color: #000;
    position: fixed;
    display: block;
    top: 20px;
    right: 20px;
    margin: 0;
    height: 600px;
    width: 400px;
    @media screen and (min-width: $desktop) {
        width: 40vw; }
    font-size: 14px;

    .h2 {
        margin: 50px 20px 20px;
        display: block;

        &.first {
            margin: 20px 0 50px 20px; } }

    .btn-close {
        display: block;
        position: absolute;
        border: none;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        background: -webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, #000 46%,  #000 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, #000 46%,  #000 56%,transparent 56%, transparent 100%); }

    .btn-link {
        display: flex;
        justify-content: left;
        width: 100%;
        border: 0;
        border-bottom: 1px $second-dark solid;
        border-radius: 0;
        background: $second;
        font-size: 16px;
        letter-spacing: 1.1px;
        text-align: left;
        padding: 0 10px;

        &.first {
            border-top: 1px $second-dark solid; }

        &:hover {
            background: $second-dark; }

        img {
            height: 35px;
            margin-right: 15px; }
        span {
            margin: auto 0; } }

    p, h2, ul {
        margin: 25px 20px; } }

.menu.contact .menu-side {
    width: 0;
    @media screen and (min-width: $desktop) {
        width: 60vw; } }
.menu.contact .menu-container {
    width: 100vw;
    @media screen and (min-width: $desktop) {
        width: 40vw; } }

.imprint, .privacy-policy {
    .address {
        display: flex;
        flex-direction: column;
        font-weight: 200;

        .txt-bold {
            font-size: 18px; } }

    .inner {
        max-height: 500px;
        @media screen and (min-width: $desktop) {
            max-height: 500px; }
        overflow-y: scroll; } }

.privacy-policy .inner {
        text-align: justify; }


.footer {
    img {
        height: 100vh;
        width: 100%;
        margin-bottom: -4px; }
    h1 {
        width: 100%;
        margin-top: 250px;
        vertical-align: middle;
        position: absolute;
        text-align: center;
        color: #f2f2f2; } }
