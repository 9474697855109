$prime: #1d1d1f;
$prime-light: #818181;
$second: #0ec756;
$second-half: #0ec75544;
$second-dark: #0cad4a;
$sky-color: rgb(32, 32, 48);
$sky-color-75: rgba(32, 32, 48, 0.75);

$phone: 640px;
$tablet: 768px;
$desktop: 1024px;
$full-desktop: 1080px;
