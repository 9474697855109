.slide-left-menu {
    animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    @media screen and (min-width: $desktop) {
        animation: d-slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; } }

.slide-right-menu {
    animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; }
.slide-back-left-menu {
    animation: slide-back-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    @media screen and (min-width: $desktop) {
        animation: d-slide-back-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; } }
.slide-back-right-menu {
    animation: slide-back-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; }

.slide-left-typecard {
    animation: slide-left-typecard 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; }
.slide-right-typecard {
    animation: slide-right-typecard 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; }

@keyframes slide-left {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0); } }
@keyframes d-slide-left {
  0% {
    transform: translateX(40vw); }
  100% {
    transform: translateX(0); } }

@keyframes slide-right {
  0% {
    transform: translateX(-60vw); }
  100% {
    transform: translateX(0); } }

@keyframes slide-back-left {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(100vw); } }
@keyframes d-slide-back-left {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(40vw); } }

@keyframes slide-back-right {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-60vw); } }


@keyframes slide-left-typecard {
  0% {
    transform: translateX(250px); }
  100% {
    transform: translateX(0); } }

@keyframes slide-right-typecard {
  0% {
    transform: translateX(-250px); }
  100% {
    transform: translateX(0); } }
